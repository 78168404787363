import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
export const FooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      background: "#F9FAF9",
      margin: "0px 0px 0px 0px",
      width: "calc(100% - 300px)",
      marginLeft: "300px",
      padding: "32px",
      justifyContent: "space-between",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        flexDirection: "column",
        width: "100%",
        marginLeft: "0px",
      },
    },
    link: {
      display: "inline-flex",
      gap: "4px",
      alignItems: "center",
      color: "#55727e",
      fontSize: "14px",
      fontWeight: 600,
      height: "auto",
      cursor: "pointer",
      "& i": {
        color: "#55727e",
      },
    },
    linkContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
      [theme.breakpoints.down(840)]: {
        flexDirection: "column",
        gap: "8px",
      },
    },
    imLink: {
      display: "flex",
      justifyContent: "center",
      marginTop: "16px",
      "& p": {
        color: "#55727e",
        fontFamily: "Josefin Sans",
        fontSize: "12px",
        fontWeight: 600,
        margin: 0,
      },
    },
  })
);
