
/** @format */

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#EEF3F2",
      padding: "80px",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        padding: "80px 48px 80px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(540)]: {
        padding: "48px 24px 48px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    container: {
      fontFamily: "Noto Sans JP",
      background: "#fff",
      borderRadius: "10px",

      padding: "24px",
      textAlign: "center",
      [theme.breakpoints.down(840)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    reportTitle: {
      marginTop: "50px",
      color: "#000",
      fontFamily: "Noto Sans JP",
      fontSize: "40px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "0px 0px 24px 0px",
      textAlign: "center",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "18px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    reportSmallTitle: {
      color: "#000",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "0px 0px 24px 0px",
      textAlign: "center",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    tableRoot: {},
    tableTool: {
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      color: "#000",
      fontSize: "14px",
      fontWeight: 600,
    },
    menuContainer: {
      margin: "6px 0",
    },
    radioBox: {
      display: "inline",
      marginRight: "6px",
    },
    formRadio: {},
    formRadioLabel: {
      fontSize: "16px",
    },
    outputCsv: {
      fontSize: "16px",
      display: "inline-block",
      marginLeft: "20px",
      cursor: "pointer",
      border: "1px solid #000000",
      borderRadius: "4px",
      padding: "2px 8px",
      background: "#eeeeee",
      "& i": {
        margin: "0 0 0 4px",
        fontWeight: 400,
        fontSize: "12px",
      },
      "&:hover": {
        opacity: 0.5,
      },
    },
    loading: {
      paddingTop: "12px",
    },
    blankText: {
      paddingTop: "24px",
      paddingBottom: "32px",
    },
  })
);
