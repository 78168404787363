import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { REQUEST_API_URL } from "../config/constant";
import { getToken, getUserId } from "Utilities/appHelper";

export async function ApiUsageReport() {
    const userId = getUserId()
    const token = getToken()
    const params = { userId: userId };
    const query = new URLSearchParams(params);
    return fetch(REQUEST_API_URL + `/service_applications_report?${query}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        headers: {
            Authorization: "Bearer " + token,
        },
    })
        .then(res => res.json())
        .then(data => {
            AfterApiIsLogin(data["result-status"]);
            return data["result-object"];
        })
}