/** @format */

import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import MessageModal from "components/MessageModal/MessageModal";
import clsx from "clsx";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStyles } from "./EmployeeEditStyles";

import { ApiUsersIdClient } from "Apis/ApiUsersIdClient";
import { ProcUsersId } from "Utilities/ProcUsersId";
import { ApiUsersUpdateClient } from "Apis/ApiUsersUpdateClient";

import {
  EMPLOYEE_LIST_LINK,
  USERS_AUTHORITIES_ADMIN_ADMIN,
  USERS_AUTHORITIES_CLIENT_ADMIN,
  USERS_AUTHORITIES_CLIENT_USER,
  USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN,
  USERS_AUTHORITIES_DISPLAY_CLIENT_USER,
  USERS_REGISTRATION_STATUS_DEFINITIVE,
} from "config/constant";
import { AfterApiIsLogin } from "Utilities/AuthUtility";
import ActionCheckbox from "components/ActionCheckbox/ActionCheckbox";
import { fullNameOrFamilyName, getClinetId } from "Utilities/appHelper";
import { ApiClientsId } from "Apis/ApiClientsId";
import { isEmpty } from "lodash";

export const EmployeeEdit = () => {
  const classes = useStyles();
  const { user_id } = useParams();
  const [userInfo, setUserInfo] = useState<any>();

  const [beforeBusinessEmail, setBeforeBusinessEmail] = useState("");
  const [beforePrivateEmail, setBeforePrivateEmail] = useState("");

  const [msgTitle, setMsgTitle] = useState("従業員情報修正完了");
  const [finishMessage, setFinishMessage] =
    useState("従業員情報を修正しました。");
  const [phoneAuthType, setPhoneAuthType] = useState<boolean>(false);

  //-----------cancel function-------------
  const handlePreviousRoute = () => {
    window.location.replace(EMPLOYEE_LIST_LINK);
  };

  const [startUsersUpdateStatus, setStartUsersUpdateStatus] = useState(false);
  const [errMsg, setErrMsg] = useState<Array<String>>([]);

  const handleNext = () => {
    setStartUsersUpdateStatus(true);

    let inputProps: any = {
      last_name: userInfo.last_name,
      first_name: userInfo.first_name,
      full_name: userInfo.full_name,
      phone: userInfo.phone,
      business_email: userInfo.business_email,
      private_email: userInfo.private_email,
      authorities_client_admin: userInfo.authorities_client_admin,
      password: userInfo.password,
      password2: userInfo.password2,
    };

    let errs = [];

    if (inputProps.full_name.length == 0) {
      errs.push("氏名が入力されていません。");
    }

    if (phoneAuthType) {
      if (inputProps.phone.length == 0) {
        errs.push("電話番号が入力されていません。");
      }
      if (!/^\d{10,11}$/.test(inputProps.phone)) {
        errs.push("正しい電話番号が入力されていません");
      }
    } else {
      if (inputProps.business_email.length == 0) {
        errs.push("会社メールアドレスが入力されていません。");
      } else {
        if (!/^[^@]+@[^@.]+.[^@]+$/.test(inputProps.business_email)) {
          errs.push("会社メールアドレスの形式が正しくありません。");
        }
      }
    }


    if (
      inputProps.private_email &&
      !/^[^@]+@[^@.]+.[^@]+$/.test(inputProps.private_email)
    ) {
      errs.push("個人メールアドレスの形式が正しくありません。");
    }
    //}

    if (!(inputProps.password === void 0) && inputProps.password.length > 0) {
      if (inputProps.password !== inputProps.password2) {
        errs.push("パスワードとパスワード（再入力）が一致しません。");
      }
      // if (inputProps.password.length > 0 && inputProps.password.length < 8) {
      //   errs.push("パスワードは8文字以上入力してください。");
      // } else {
        /* if (!(/^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/).test(inputProps.password)) { */
        // if (
        //   !(
        //     inputProps.password.match(/([a-zA-Z])/) &&
        //     inputProps.password.match(/([0-9])/)
        //   )
        // ) {
        //   errs.push("パスワードは英数字混合にしてください。");
        // } else {
        // }
      // }
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
      setErrorMessageModalStatus(true);
    } else {
      usersUpdate(inputProps);
    }
  };

  const [editUserFinishStatus, setEditUserFinishStatus] = useState(false);

  const [errMsg2, setErrMsg2] = useState("");
  const [errMsg3, setErrMsg3] = useState("");

  const usersUpdate = async (e: any) => {
    var obj: any = {};
    obj = {
      id: userInfo.id,
      last_name: e.last_name,
      first_name: e.first_name,
      full_name: e.full_name,
      phone: e.phone,
      business_email: e.business_email,
      private_email: e.private_email,
      image_id: userInfo.image_id,
      authorities_client_admin: e.authorities_client_admin,
      password: e.password ? e.password : null,
      enable: userInfo.enable,
    };

    var response: any = await ApiUsersUpdateClient(obj);
    loginCheck(response);
    setErrMsg2("");
    if (response["result-status"] === 200) {
      if (
        beforeBusinessEmail !== obj.business_email ||
        (beforePrivateEmail !== obj.private_email && obj.private_email)
      ) {
        setMsgTitle("従業員情報を修正");
        setFinishMessage(response.message);
      }
      setEditUserFinishStatus(true);
    } else {
      setErrMsg2(
        "ApiUsersUpdateClient\nresult-status = " +
        response["result-status"] +
        "\nmessage = " +
        response.message
      );
      setErrorMessage2ModalStatus(true);
    }
  };

  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }

  function handleEditUserFinish() {
    setEditUserFinishStatus(false);
    window.location.replace(EMPLOYEE_LIST_LINK);
  }

  const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);
  const [errorMessage2ModalStatus, setErrorMessage2ModalStatus] =
    useState(false);
  const [errorMessage3ModalStatus, setErrorMessage3ModalStatus] =
    useState(false);

  function handleErrorMessageModal() {
    setErrorMessageModalStatus(false);
  }

  function handleErrorMessage2Modal() {
    setErrorMessage2ModalStatus(false);
  }

  function handleErrorMessage3Modal() {
    setErrorMessage3ModalStatus(false);
  }

  const handleAuthoritiesClientAdmin = (e: any) => {
    if (userInfo.id === localStorage.getItem("user_key")) {
      setErrMsg3("現在ログインしているユーザー自身の無効化はできません。");
      setErrorMessage3ModalStatus(true);
      return;
    }

    setUserInfo({
      ...userInfo,
      authorities_client_admin: e,
    });
  };

  //----------init function-------------------------
  const init = async () => {
    ApiUsersIdClient(callBackApiUsersIdClient(ProcUsersId), user_id);
  };

  function callBackApiUsersIdClient(func: any) {
    return (data: any) => {
      const res = func(data);
      const userRes = {
        id: res.id,
        last_name: res.last_name,
        first_name: res.first_name,
        full_name: res.full_name,
        phone: res.phone,
        business_email: res.business_email,
        private_email: res.private_email,
        password: "",
        password2: "",
        image_id: res.image_id,
        authorities_client_admin: res.authorities.includes(
          USERS_AUTHORITIES_CLIENT_ADMIN
        ),
        enable: res.enable,
        registration_status: res.registration_status,
        create_at: res.create_at,
      };

      setBeforeBusinessEmail(res.business_email);
      setBeforePrivateEmail(res.private_email);

      setUserInfo(userRes);
    };
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (startUsersUpdateStatus === true && editUserFinishStatus === false) {
      window.location.replace(EMPLOYEE_LIST_LINK);
    }
  }, [editUserFinishStatus]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.title}>従業員情報編集</div>
          <div className={classes.descContainer}>各項目を編集します。</div>
          <div className={classes.mainContainer}>
            <div className={classes.errorMessage}>
              {errMsg.length > 0 ? (
                <>
                  <div>入力エラー</div>
                  <ul>
                    {errMsg.map((item: any, key: any) => {
                      return <li key={key}>{item}</li>;
                    })}
                  </ul>
                </>
              ) : null}
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>
                氏名 <span className={classes.symbol}>*</span>
              </div>
              {userInfo ? (
                <ActionInput
                  value={fullNameOrFamilyName(
                    userInfo?.full_name,
                    userInfo?.first_name,
                    userInfo?.last_name
                  )}
                  action={(e) =>
                    setUserInfo({
                      ...userInfo,
                      full_name: e,
                    })
                  }
                  placeholder="しさく くらら"
                  className={classes.formMiddle}
                  autoComplete="off"
                />
              ) : (
                ""
              )}
            </div>
            {phoneAuthType ? (
              <div className={classes.form}>
                <div className={classes.formTitle}>
                  電話番号 <span className={classes.symbol}>*</span>
                </div>
                {userInfo ? (
                  <ActionInput
                    value={userInfo?.phone}
                    action={(e) =>
                      setUserInfo({
                        ...userInfo,
                        phone: e,
                      })
                    }
                    placeholder="09012345678"
                    className={classes.formMiddle}
                    autoComplete="off"
                    disable={
                      userInfo?.registration_status !==
                      USERS_REGISTRATION_STATUS_DEFINITIVE
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                <div className={classes.form}>
                  <div className={classes.formTitle}>
                    会社メールアドレス <span className={classes.symbol}>*</span>
                  </div>
                  {userInfo ? (
                    <ActionInput
                      value={userInfo?.business_email}
                      action={(e) =>
                        setUserInfo({
                          ...userInfo,
                          business_email: e,
                        })
                      }
                      placeholder="xxx@xxxx.xx.xx"
                      className={classes.formMiddle}
                      autoComplete="off"
                      disable={
                        userInfo?.registration_status !==
                        USERS_REGISTRATION_STATUS_DEFINITIVE
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>
                    権限 <span className={classes.symbol}>*</span>
                  </div>
                  <div className={classes.checkboxFont}>
                    <ActionCheckbox
                      type="checkbox"
                      value={USERS_AUTHORITIES_DISPLAY_CLIENT_USER}
                      checked={true}
                      readOnly={true}
                      action={() => void 0}
                    />
                    {USERS_AUTHORITIES_DISPLAY_CLIENT_USER}
                  </div>
                  <div className={classes.checkboxFont}>
                    <ActionCheckbox
                      type="checkbox"
                      value={USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN}
                      checked={
                        userInfo?.authorities_client_admin
                          ? userInfo?.authorities_client_admin
                          : ""
                      }
                      action={(e) => handleAuthoritiesClientAdmin(e)}
                    />
                    {USERS_AUTHORITIES_DISPLAY_CLIENT_ADMIN}
                  </div>
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>
                    パスワード<span className={classes.kome}>（※1）</span>
                  </div>
                  {userInfo ? (
                    <ActionInput
                      type="password"
                      value={userInfo?.password}
                      action={(e) =>
                        setUserInfo({
                          ...userInfo,
                          password: e,
                        })
                      }
                      placeholder="xxxxxxxx"
                      className={classes.formShort}
                      autoComplete="off"
                      disable={
                        userInfo?.registration_status !==
                        USERS_REGISTRATION_STATUS_DEFINITIVE
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>
                    パスワード（再入力）<span className={classes.kome}>（※1）</span>
                  </div>
                  {userInfo ? (
                    <ActionInput
                      type="password"
                      value={userInfo?.password2}
                      action={(e) =>
                        setUserInfo({
                          ...userInfo,
                          password2: e,
                        })
                      }
                      placeholder="xxxxxxxx"
                      className={classes.formShort}
                      autoComplete="off"
                      disable={
                        userInfo?.registration_status !==
                        USERS_REGISTRATION_STATUS_DEFINITIVE
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.form}>
                  <div className={classes.formDiscription}>
                    ※1 パスワードを変更する場合のみご入力ください
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.btnContainer}>
            <ActionButton
              content="キャンセル"
              className={classes.cancelBtn}
              action={handlePreviousRoute}
            />
            <ActionButton
              content="登録"
              type="dark"
              className={classes.confirmBtn}
              action={() => handleNext()}
            />
          </div>
          <MessageModal
            title={msgTitle}
            description={finishMessage}
            show={editUserFinishStatus}
            onClose={() => setEditUserFinishStatus(false)}
            action={handleEditUserFinish}
          />
          <MessageModal
            title="入力エラー"
            description={`入力エラーがあります。画面上部に表示しているエラーメッセージをご確認ください。`}
            show={errorMessageModalStatus}
            onClose={() => setErrorMessageModalStatus(false)}
            action={handleErrorMessageModal}
          />
          <MessageModal
            title="従業員情報修正エラー"
            description={`従業員情報修正エラーが発生しました。\n\n<<エラーメッセージ>>\n${errMsg2}`}
            show={errorMessage2ModalStatus}
            onClose={() => setErrorMessage2ModalStatus(false)}
            action={handleErrorMessage2Modal}
          />
          <MessageModal
            title="エラー"
            description={errMsg3}
            show={errorMessage3ModalStatus}
            onClose={() => setErrorMessage3ModalStatus(false)}
            action={handleErrorMessage3Modal}
          />
        </div>
      </div>
    </>
  );
};
