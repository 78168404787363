export function ProcUsages(data: any) {
    var result:any = [];
    data.forEach((item: any, key: any) => {
        var obj = {
            id: item.id,
            question: item.question,
            answer: item.answer,
        }
        result.push(obj);
    })
    return result;
}