import { getToken, getUserId } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiClientsId(id: any) {
    const token = getToken()
    const userId = getUserId()
    const params = { userId: userId };
    const query = new URLSearchParams(params);

    const response: any = await fetch(REQUEST_API_URL + '/clients/' + id + `?${query}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            Authorization: "Bearer " + token
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
    })

    return response.json();
}
