import { DropSideMenuType } from "config/constant";
import { useStyles } from "./DropSideMenuStyle";
import { Link } from "react-router-dom";

export interface DropSideMenuProps {
  children: DropSideMenuType[];
}

const DropSideMenu = (props: DropSideMenuProps) => {
  const classes = useStyles();

  return (
    <div className={classes.dropdownMenu}>
      <ul className={classes.ul}>
        {props.children.map((item, idx) => {
          return (
            <Link to={item.url}>
              <li className={classes.li} key={idx}>
                {item.text}
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default DropSideMenu;
