import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LayoutStyles } from "./LayoutStyle";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";
import { ErrorRoute } from "pages/ErrorRoute/ErrorRoute";
import { EmployeeList } from "pages/EmployeeList/EmployeeList";
import { EmployeeDetail } from "pages/EmployeeList/EmployeeDetail/EmployeeDetail";
import { EmployeeEdit } from "pages/EmployeeList/EmployeeEdit/EmployeeEdit";
import { Contact } from "pages/Contact/Contact";
import { Usage } from "pages/Usage/Usage";
import { Login } from "pages/Login/Login";
import {
  ACCESS_REPORT_LINK,
  APPLICATION_REPORT_LINK,
  CONTACT_COMPLETE_PAGE_LINK,
  CONTACT_LINK,
  EMPLOYEE_DETAIL_LINK,
  EMPLOYEE_EDIT_LINK,
  EMPLOYEE_LIST_LINK,
  FIRST_LOGIN_REPORT_LINK,
  USAGE_LINK,
  USAGE_REPORT_LINK,
} from "config/constant";
import { createLoginMonitor } from "Utilities/LoginManager";
import { Complete } from "pages/Complete/Complete";
import { useEffect, useState } from "react";
import { ApplicationReport } from "pages/Report/ApplicationReport";
import { FirstLoginReport } from "pages/Report/FirstLoginReport";
import { LoginReport } from "pages/Report/LoginReport";
import { UsageReport } from "pages/Report/UsageReport";

export default function Layout() {
  const loginMonitor = createLoginMonitor();

  const classes = LayoutStyles();

  const [beforeunloadFlg, setBeforeunloadFlg] = useState(false);

  const handleBeforeUnloadEvent = async () => {
    setBeforeunloadFlg(true);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
  }, []);

  return (
    <>
      {beforeunloadFlg ? (
        <div className={classes.root}></div>
      ) : (
        <Router>
          <Sidebar loginStatusForSidebar={loginMonitor.isLogin()} />
          <div className={classes.container}>
            {/* <Header /> */}
            <Routes>
              <Route
                path="/"
                element={loginMonitor.isLogin() ? <EmployeeList /> : <Login />}
              />
              <Route
                path={EMPLOYEE_LIST_LINK}
                element={loginMonitor.isLogin() ? <EmployeeList /> : <Login />}
              />
              <Route
                path={`${EMPLOYEE_DETAIL_LINK}/:user_id`}
                element={
                  loginMonitor.isLogin() ? <EmployeeDetail /> : <Login />
                }
              />
              <Route
                path={`${EMPLOYEE_EDIT_LINK}/:user_id`}
                element={loginMonitor.isLogin() ? <EmployeeEdit /> : <Login />}
              />
              <Route
                path={ACCESS_REPORT_LINK}
                element={loginMonitor.isLogin() ? <LoginReport /> : <Login />}
              />
              <Route
                path={APPLICATION_REPORT_LINK}
                element={
                  loginMonitor.isLogin() ? <ApplicationReport /> : <Login />
                }
              />
              <Route
                path={USAGE_REPORT_LINK}
                element={
                  loginMonitor.isLogin() ? <UsageReport/> : <Login />
                }
              />
              <Route
                path={FIRST_LOGIN_REPORT_LINK}
                element={
                  loginMonitor.isLogin() ? <FirstLoginReport /> : <Login />
                }
              />
              <Route
                path={`${USAGE_LINK}`}
                element={loginMonitor.isLogin() ? <Usage /> : <Login />}
              />
              <Route
                path={`${CONTACT_LINK}`}
                element={loginMonitor.isLogin() ? <Contact /> : <Login />}
              />
              <Route
                path="/404"
                element={loginMonitor.isLogin() ? <ErrorRoute /> : <Login />}
              />
              <Route
                path={CONTACT_COMPLETE_PAGE_LINK}
                element={
                  <Complete
                    title="お問い合わせ完了"
                    body="ご入力いただいたメールアドレスに数分以内に
詳細が記載されたメールが届きますのでご確認いただけますと幸いです。" /* "1営業日以内にご返信させていただきます。" */
                  />
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="*"
                element={loginMonitor.isLogin() ? <ErrorRoute /> : <Login />}
              />
            </Routes>
          </div>
          <Footer />
        </Router>
      )}
    </>
  );
}
