/** @format */

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 160px)",
      backgroundColor: "#EEF3F2",
      padding: "80px",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        padding: "80px 48px 80px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(540)]: {
        padding: "48px 24px 48px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    container: {
      fontFamily: "Noto Sans JP",
      background: "#fff",
      borderRadius: "10px",
      padding: "24px 32px",
      [theme.breakpoints.down(840)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "12px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "12px",
      margin: "8px 0 24px"
    },
    loginRateContainer: {
      padding: "16px 20px",
      borderRadius: "8px",
      border: "1px solid #DCE5E5",
      backgroundColor: "#F6F7F9",
      textAlign: "center",
    },
    loginRateTitle: {
      color: "#797F7F",
      fontSize: "14px",
      fontWeight: 600,
    },
    loginRateLarge: {
      fontSize: "24px",
      fontWeight: 600,
    },
    loginRateSmall: {
      fontSize: "14px",
      fontWeight: 600,
      paddingLeft: "4px",
    },
    reportTitle: {
      color: "#242727",
      fontFamily: "Noto Sans JP",
      fontSize: "36px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "8px 0px 16px 0px",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "18px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    reportSmallTitle: {
      color: "#242727",
      fontSize: "16px",
      fontWeight: 300,
      height: "auto",
      lineHeight: 1.4,
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "16px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    chartContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "40px",
      marginTop: "40px",
      marginBottom: "24px",
      [theme.breakpoints.down(440)]: {
        paddingRight: "20px",
      },
    },
    chartContainerLegend: {
      color: "#242727",
      fontSize: "14px",
      fontWeight: "bold",
    }
  })
);
