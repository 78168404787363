import React from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  StyledPaginationBtn,
  StyledPageInfoBtn,
  useStyles,
} from "./PaginationStyles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
}

export default function Pagination(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const classes = useStyles();

  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  const handleLastPageButtonClick = () => {
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <div className={classes.paginationRoot}>
        <StyledPaginationBtn
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? "Last" : "First"}
        </StyledPaginationBtn>
        <StyledPaginationBtn
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </StyledPaginationBtn>
        <StyledPageInfoBtn>
          Page {page + 1} of {Math.max(1, Math.ceil(count / rowsPerPage))}
        </StyledPageInfoBtn>
        <StyledPaginationBtn
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </StyledPaginationBtn>
        <StyledPaginationBtn
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? "First" : "Last"}
        </StyledPaginationBtn>
      </div>
    </>
  );
}
