import { ApiUsagesTypeid } from "Apis/ApiUsagesTypeid";
import { USAGES_TYPE_ID_CLIENT_ADMIN } from "config/constant";
import { useEffect, useState } from "react";
import { ProcUsages } from "Utilities/ProcUsages";
import { UsageCard } from "./component/UsageCard/UsageCard";
import { UsageStyles } from "./UsageStyle";

var usageListArray: any[] = [];

export const Usage = () => {
  const classes = UsageStyles();

  const [dataAdd, setDataAdd] = useState<boolean>(false);

  useEffect(() => {
    usageListArray = [];
    ApiUsagesTypeid(makeCallBack(usageListArray, ProcUsages), USAGES_TYPE_ID_CLIENT_ADMIN);
  }, []);

  function makeCallBack(list: any, func: any) {
    return (data: any) => {
      func(data).forEach((item: any, key: any) => {
        list.push(item)
      });
      setData(true);
    }
  }

  function setData(updateUseState: boolean) {
    if (updateUseState) {
      setDataAdd(true);
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div>
          <h2 className={classes.usageTitle}>利用方法</h2>
        </div>
        {
          usageListArray.map((item: any, key: any) => {
            return (
              <UsageCard key={key} question={item.question} answer={item.answer} />
            );
          })
        }
      </div>
    </>
  );
};
