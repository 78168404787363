/** @format */
import { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { useStyles } from "./UsageReportStyles";
import { ApiUsageReport } from "Apis/ApiUsageReport";

export const UsageReport = () => {
  const classes = useStyles();
  const [reportData, setReportData] = useState<{
    allUsers: number;
    loggedUsers: number;
    currentMonth: string;
    data: Array<any>;
  }>({
    allUsers: 1,
    loggedUsers: 0,
    currentMonth: new Date().toLocaleDateString("ja-JP", { month: "long" }),
    data: []
  });
  const [outputCsvFetchDone, setOutputCsvFetchDone] = useState(false);
  const outputCsvFetchDoneRef = useRef<any>();

  const filterData = (reportData: any) => {
    return reportData.data.map((item: any, index: number) => {
      return {
        name: item.month,
        zero: Math.max(0, item.all - item.one - item.two - item.three - item.four - item.more),
        one: item.one,
        two: item.two,
        three: item.three,
        four: item.four,
        more: item.more
      }
    })
  }
  const roundToDecimalPlaces = (value: number, decimalPlaces: number) => {
    return Math.round(value * decimalPlaces) / decimalPlaces;
  };

  useEffect(() => {
    (async () => {
      const data = await ApiUsageReport();
      setReportData(data);
    })();
  }, []);

  useEffect(() => {
    if (outputCsvFetchDone) {
      outputCsvFetchDoneRef.current.link.click();
      setOutputCsvFetchDone(false);
    }
  }, [outputCsvFetchDone]);

  //-------------filter function----------
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div>
            <div className={classes.reportTitle}>利用率レポート</div>
            <div className={classes.reportSmallTitle}>
              従業員のギフトと掲載サービスの利用率を表示しています。
            </div>
          </div>
          <div>
            <div className={classes.loginRateContainer}>
              <div className={classes.loginRateTitle}>今月のサービス利用率</div>
              <div>
                <span className={classes.loginRateLarge}>
                  {roundToDecimalPlaces((reportData?.loggedUsers || 0) / (reportData?.allUsers || 1) * 100, 10)}
                </span>
                <span className={classes.loginRateSmall}>
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.chartContainer}>
          <BarChart
            width={600}
            height={400}
            data={filterData(reportData)}
            margin={{
              top: 0,
              right: 30,
              left: 0,
              bottom: 5
            }}
            barSize={64}
            barGap={16}
          >
            <XAxis 
              dataKey="name"
              scale="point"
              padding={{ left: 50, right: 10 }}
              tick={{fontSize: "13px", fontWeight: "bold"}}
            />
            <YAxis
              domain={[0, reportData.allUsers]}
              tick={{fontSize: "13px", fontWeight: "bold"}}
              tickFormatter={(value) => `${roundToDecimalPlaces(value / reportData.allUsers * 100, 1)}`}
              ticks={[0, reportData.allUsers / 4 * 1, reportData.allUsers / 2, reportData.allUsers / 4 * 3, reportData.allUsers]}
              unit="%"
            />
            <Tooltip 
              contentStyle={{fontWeight: 'bold', backgroundColor: "#FFF", borderRadius: "8px", borderColor: "#DCE5E5", fontSize: "13px", padding: "8px 12px"}}
              labelStyle={{marginBottom: "8px", color: "#797F7F"}}
              itemStyle={{color: "#242727", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0", margin: "0"}}
            />
            <Legend
              verticalAlign="top"
              height={32}
              align="center"
              formatter={(value) => <span className={classes.chartContainerLegend}>{value}</span>}
              wrapperStyle={{width: "100%"}}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar name="５回以上" dataKey="more" stackId="bar" fill="#00847C" />
            <Bar name="４回" dataKey="four" stackId="bar" fill="#02A59B" />
            <Bar name="３回" dataKey="three" stackId="bar" fill="#01C7BB" />
            <Bar name="２回" dataKey="two" stackId="bar" fill="#27DED3" />
            <Bar name="１回" dataKey="one" stackId="bar" fill="#7AEFE8" />
            <Bar name="０回" dataKey="zero"  stackId="bar" fill="#F6F7F9" />
          </BarChart>
        </div>
      </div>
    </div>
  );
};
