import ActionButton from "components/ActionButton/ActionButton";
import MessageModal from "components/MessageModal/MessageModal";
import ActionSelect from "components/ActionSelect/ActionSelect";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContactStyles } from "./ContactStyle";

import {
  CONTACT_COMPLETE_PAGE_LINK,
  QUESTION_TYPE_ID_FROM_CLIENT_SITE,
} from "config/constant";

import { ApiUsersId } from "Apis/ApiUsersId";
import { ApiQuestion } from "Apis/ApiQuestion";
import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { getUser } from "Auth/authClient";
import { getUserId } from "Utilities/appHelper";

export const Contact = () => {
  const classes = ContactStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const initialUserValues = {
    id: "",
    client_id: "",
    last_name: "",
    first_name: "",
    phone: "",
    business_email: "",
    private_email: "",
    image_id: "",
  };

  const [userValues, setUserValues] = useState(initialUserValues);
  const [emailType, setEmailType] = useState("0");
  const [question, setQuestion] = useState("");
  const [emailList, setEmailList] = useState<any>();

  useEffect(() => {
    ApiUsersId(callBackApiUsersId(), localStorage.getItem("user_key"));
  }, []);

  function callBackApiUsersId() {
    return (data: any) => {
      setUserValues({
        ...userValues,
        id: data.id,
        client_id: data.client_id,
        last_name: data.last_name,
        first_name: data.first_name,
        phone: data.phone,
        business_email: data.business_email,
        private_email: data.private_email,
        image_id: data.image_id,
      });

      var list = [
        "ご回答先のメールアドレスをお選びください",
        data.business_email,
      ];
      if (data.private_email) {
        list.push(data.private_email);
      }
      setEmailList(list);
    };
  }

  const handleEmailType = (e: any) => {
    setEmailType(e.target.value);
  };

  const handleQuestion = (e: any) => {
    setQuestion(e.target.value);
  };

  const [errMsg, setErrMsg] = useState<Array<String>>([]);
  const [errMsg2, setErrMsg2] = useState("");

  const handleSend = async () => {
    let errs = [];

    if (emailType === "0") {
      errs.push("・メールアドレスが選択されていません。");
    }

    if (question.length == 0) {
      errs.push("・お問い合わせ内容欄に内容が入力されていません。");
    }

    setErrMsg([]);
    if (errs.length > 0) {
      let temp = errs;
      setErrMsg(temp);
      setErrorMessageModalStatus(true);
    } else {
      const userId = getUserId()
      const data = {
        question: question,
        email_type: emailType,
        type_id: QUESTION_TYPE_ID_FROM_CLIENT_SITE,
        userId: userId,
      };
      const res = await ApiQuestion(data);

      loginCheck(res);
      setErrMsg2("");
      if (res["result-status"] === 200) {
        window.location.replace(CONTACT_COMPLETE_PAGE_LINK);
      } else {
        setErrMsg2(
          "ApiQuestion\nresult-status = " +
          res["result-status"] +
          "\nmessage = " +
          res.message
        );
        setErrorMessage2ModalStatus(true);
      }
    }
  };

  const [errorMessageModalStatus, setErrorMessageModalStatus] = useState(false);
  const [errorMessage2ModalStatus, setErrorMessage2ModalStatus] =
    useState(false);

  function handleErrorMessageModal() {
    setErrorMessageModalStatus(false);
  }

  function handleErrorMessage2Modal() {
    setErrorMessage2ModalStatus(false);
  }

  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.contactTitle}>
            弊社へのお問い合わせは
            <br />
            こちらのフォームより承っております。
          </div>
          <div className={classes.contactForm}>
            <div className={classes.formTitle}>
              メールアドレス <span className={classes.symbol}>*</span>
            </div>
            {/* <select className={classes.formInput} onChange={(e) => handleEmailType(e)}>
              <option>ご回答先のメールアドレスをお選びください</option>
              <option value="1">会社用メールアドレス（{userValues.business_email}）</option>
              <option value="2">個人用メールアドレス（{userValues.private_email}）</option>
            </select> */}
            <ActionSelect
              items={emailList}
              select={(e) => handleEmailType(e)}
              placeholder={emailList ? emailList[0] : ""}
              defaultValue={emailType}
              value={emailType}
            />
          </div>
          <div className={classes.contactForm}>
            <div className={classes.formTitle}>
              お問い合わせ内容 <span className={classes.symbol}>*</span>
            </div>
            <textarea
              className={classes.formTextarea}
              value={question}
              placeholder="お問い合わせ内容"
              onChange={(e) => handleQuestion(e)}
            />
          </div>
          <ActionButton
            type="dark"
            content="この内容で送信する"
            className={classes.formBtn}
            action={handleSend}
          />
        </div>
      </div>
      <MessageModal
        title="エラー"
        description={`${errMsg.join("\n")}`}
        show={errorMessageModalStatus}
        onClose={() => setErrorMessageModalStatus(false)}
        action={handleErrorMessageModal}
      />
      <MessageModal
        title="お問い合わせエラー"
        description={`お問い合わせエラーが発生しました。\n\n<<エラーメッセージ>>\n${errMsg2}`}
        show={errorMessage2ModalStatus}
        onClose={() => setErrorMessage2ModalStatus(false)}
        action={handleErrorMessage2Modal}
      />
    </>
  );
};
