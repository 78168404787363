import { useStyles } from "./TableContentStyles";
import { TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableRoot from "components/Table/TableBase/TableRoot";
import {
  USERS_REGISTRATION_STATUS_DEFINITIVE,
} from "config/constant";
import { fullNameOrFamilyName } from "Utilities/appHelper";
import { AuthType } from "types/authType";

interface StagingTableProps {
  columns?: any;
  rows: any;
  pageNumber: number;
  perPageNumber: number;
  sortHeader: number;
  reverseFlg: boolean;
  authType: AuthType;
  headerSort: (e: any) => void;
  //onRow: (e: any) => void;
}

const TableContent = ({
  pageNumber,
  perPageNumber,
  columns,
  rows,
  sortHeader,
  reverseFlg,
  authType,
  headerSort,
}: //onRow,
  StagingTableProps) => {
  const classes = useStyles();
  const tableRows =
    rows !== undefined ? (
      rows
        ?.slice(pageNumber * perPageNumber, (pageNumber + 1) * perPageNumber)
        .map((row: any, key: any) => (
          <TableRow key={key}>
            <TableCell className={clsx(classes.tableCell)}>
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.no}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {fullNameOrFamilyName(
                  row.full_name,
                  row.first_name,
                  row.last_name
                )}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.business_email}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.authorities_short_display}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.registration_status_display}
              </span>
            </TableCell>
            <TableCell
              className={clsx(
                classes.tableCell,
                row.registration_status !==
                USERS_REGISTRATION_STATUS_DEFINITIVE && classes.greyout
              )}
            >
              <span
                // to={`${EMPLOYEE_DETAIL_LINK}/${row.id}`}
                className={classes.cellA}
              >
                {row.create_at_display}
              </span>
            </TableCell>
          </TableRow>
        ))
    ) : (
      <></>
    );
  return (
    <>
      {rows?.length === 0 || rows === null ? (
        <div className={classes.emptyTable}>データなし</div>
      ) : (
        <TableRoot
          columns={columns}
          rows={tableRows}
          sortHeader={sortHeader}
          reverseFlg={reverseFlg}
          headerClick={(e) => headerSort(e)}
        />
      )}
    </>
  );
};

export default TableContent;
