import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  ul: {
    margin: "0",
    padding: "0",
    listStyle: "none",
    zIndex: 4,
  },

  li: {
    color: "white",
    padding: "8px",
    width: "auto",
    transition: "all linear 0.2s",
    fontSize: "14px",
    fontWeight: "bold",
    zIndex: 4,
    textAlign: "left",
    "&:hover": {
      opacity: 0.5,
    },
  },

  dropdownMenu: {
    position: "absolute",
    top: 0,
    right: -160,
    background: "#364750",
    padding: "12px",
    boxShadow: "30px 50px 30px rgb(0 0 0 / 10%)",
    transition: "all linear 0.2s",
    zIndex: 4,
    borderRadius: "8px",
  },
}));
