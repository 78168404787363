export function ProcUsers(data: any) {
  return data.map((item: any, key: any) => {
    return {
      id: item.id,
      client_id: item.client_id,
      last_name: item.last_name,
      first_name: item.first_name,
      full_name: item.full_name,
      phone: item.phone,
      business_email: item.business_email,
      private_email: item.private_email,
      authorities: item.authorities,
      image_id: item.image_id,
      enable: item.enable,
      create_at: item.create_at,
      registration_status: item.registration_status,
      done_first_login: item.done_first_login,
    };
  });
}
