import parse, { domToReact } from 'html-react-parser'
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { useState } from "react";
import UsageCardEditModal from "./UsageCardEditModal/UsageCardEditModal";
import { UsageCardStyle } from "./UsageCardStyle";

interface UsageCardProps {
  question: string;
  answer: string;
}

export const UsageCard = ({
  question,
  answer,
}: UsageCardProps) => {
  const classes = UsageCardStyle();

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);

  const handleConfirmModalOpen = () => {
    setDeleteModalStatus(true);
  };

  const handleConfirmModalClose = () => {
    setDeleteModalStatus(false);
  };

  const handleEditModalOpen = () => {
    setEditModalStatus(true);
  };

  const handleEditModalClose = () => {
    setEditModalStatus(false);
  };

  const replace = (node: any) => {
    if (node.attribs?.style !== void 0) {
      var aaa = node.attribs.style.split(";");
      var bbb: any = [];
      aaa.forEach((item: any, key: any) => {
        var tmp = item.split(":");
        bbb.push(tmp[0]?.trim().replace(/-./g, (c: string) => c.substr(1).toUpperCase()));
        bbb.push(tmp[1]?.trim());
      });
      var ccc: any = {};
      for (var i = 0; i < bbb.length - 2; i += 2) {
        var ddd: any = {};
        ddd[bbb[i]] = bbb[i + 1];
        Object.assign(ccc, ddd);
      }
      node.attribs.style = ccc;
    }
    if (node.name === 'a') {
      return (
        <a {...node.attribs} rel="noreferrer" >
          {domToReact(node.children)}
        </a>
      )
    }
    if (node.name === 'img') {

      return (
        <img {...node.attribs} className={classes.usageImg} />
      )
    }
    if (node.name === 'ul') {
      return (
        <ul {...node.attribs} className={classes.usageUl}>
          {domToReact(node.children)}
        </ul>
      )
    }
    if (node.name === 'p') {
      return (
        <p {...node.attribs} className={classes.usageP}>
          {domToReact(node.children, { replace })}
        </p>
      )
    }
  }

  return (
    <>
      <div className={classes.root}>
        {/*  <div id='tool' className={classes.toolRoot}>
          <i className='fas fa-edit' onClick={handleEditModalOpen}></i>
          <i className='fas fa-trash-alt' onClick={handleConfirmModalOpen}></i>
        </div> */}
        <div className={classes.title}>
          {parse(question, { replace })}
        </div>
        <div className={classes.des}>
          {parse(answer, { replace })}
        </div>
      </div>
      <ConfirmModal
        title='使用法を削除'
        btitle='確認'
        description='この使用カードを削除してもよろしいですか？'
        show={deleteModalStatus}
        onClose={handleConfirmModalClose}
        action={() => { }}
      />
      <UsageCardEditModal
        action={() => { }}
        show={editModalStatus}
        onClose={handleEditModalClose}
      />
    </>
  );
};
