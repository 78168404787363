import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    fontSize: "16px !important",
    fontFamily: "Noto Sans JP",//20230111
    lineHeight: "unset",
    fontWeight: "normal",
    "&.MuiTableCell-root": {
      //padding: "10px 10px 10px 20px !important",
      padding: 0,
    },
  },
  emptyTable: {
    fontFamily: "Noto Sans JP",//20230111
    fontSize: "40px",
    color: "white",
    textAlign: "center",
    margin: "100px auto",
    height: "326px",
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionIcon: {
    "& i": {
      cursor: "pointer",
    },
    "&:hover": {
      opacity: 0.5,
    },
  },
  marginRight10: {
    marginRight: "5px",
  },
  checkbox: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#F59B8A",
    },
  },
  greyout: {
    //background: "#ebeff2",
    color: "#9ab0bc",
  },
  cellA: {
    padding: "16px 10px 16px 20px !important",
    lineHeight: 3.6,
    color: "inherit",
  },
  tableCellPadding: {
    padding: "10px 10px 10px 20px !important",
  },
}));
