import ActionButton from "components/ActionButton/ActionButton";
import { useNavigate } from "react-router-dom";
import { CompleteStyles } from "./CompleteStyle";

interface CompleteData {
  title: string,
  body: string,
  modalEndButtonComment?: string,
  modalEndButtonPath?: string
}

export const Complete = (props:CompleteData) => {
  const classes = CompleteStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.CompleteTitle}>{props.title}</div>
        <div className={classes.CompleteSmallTitle}>
          {props.body}
        </div>
        <ActionButton
          type='dark'
          content={
            props.modalEndButtonComment ?? 'HOME へ戻る' // 文字を与えるとボタンに反映する
          }
          action={() => navigate(
            props.modalEndButtonPath ?? "/" //ルーティングのパスを与えるとそのパスへのリンクを貼る
          )} 
        />
      </div>
    </div>
  );
};