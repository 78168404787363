import {
  LOGIN_HISTORYS_LOGIN_TYPE_NOMAL,
  LOGIN_HISTORYS_LOGIN_TYPE_REMEMBER_ME,
  LOGIN_SITE_CLIENT,
  LOGIN_SITE_SECURE,
  USERS_AUTHORITIES_CLIENT_ADMIN,
  USERS_AUTHORITIES_CLIENT_USER,
} from "config/constant";

export function ProcLoginHistorys(data: any) {
  var result: any = [];
  data.forEach((item: any) => {
    result.push({
      id: item.id,
      create_at: item.create_at,
      user_id: item.user_id?._id,
      last_name: item.user_id?.last_name,
      first_name: item.user_id?.first_name,
      user_name: item.user_id
        ? item.user_id.last_name + " " + item.user_id.first_name
        : "削除済もしくは表示不可のユーザー",
      user_name_display: item.user_id.full_name,
      business_email: item.user_id?.business_email,
      business_email_display: item.user_id ? item.user_id.business_email : "-",
      client_id: item.user_id?.client_id,
      user_enable: item.user_id?.enable,
      user_enable_string: item.user_id
        ? item.user_id.enable
          ? "有効"
          : "無効"
        : "-",
      devise: item.devise,
    });
  });
  return result;
}
