/*import clsx from "clsx";
import { useEffect, useState } from "react";
import { ActionInputStyles } from "./ActionInputStyles";

interface ActionInputProps {
  className?: any;
  placeholder?: string;
  defaultValue: string;
  action: (e: any) => void;
  disable?: boolean;
  type?: string;
}

export default function ActionInput({
  className,
  placeholder,
  defaultValue,
  action,
  disable,
  type,
}: ActionInputProps) {
  const classes = ActionInputStyles();

  const [value, setValue] = useState("");

  const handleInput = (e: any) => {
    setValue(e.target.value);
    action(e);
  };

  useEffect(() => {

    setValue(defaultValue);

  }, [defaultValue]);

  return (
    <input
      type={type}
      className={clsx(classes.root, className)}
      value={value}
      placeholder={placeholder}
      onChange={(e) => handleInput(e)}
    />
  );
}*/

import clsx from "clsx";
import { ActionInputStyles } from "./ActionInputStyles";

interface ActionInputProps {
  className?: any;
  placeholder?: string;
  value: string;
  action: (e: any) => void;
  disable?: boolean;
  type?: string;
  autoComplete?: string;

}

export default function ActionInput({
  className,
  placeholder,
  value,
  action,
  disable,
  type,
  autoComplete,
}: ActionInputProps) {
  const classes = ActionInputStyles();

  return (
    <input
      type={type}
      className={clsx(classes.root, className)}
      value={value}
      placeholder={placeholder}
      onChange={(e) => action(e.target.value)}
      autoComplete={autoComplete}
      disabled={disable}
      />
  );
}
