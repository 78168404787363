import clsx from "clsx";
import { useEffect, useState } from "react";
import { useStyles } from "./ActionSelectStyles";

interface ActionSelectProps {
  className?: any;
  items: any;
  select: (e: string) => void;
  placeholder?: string;
  defaultValue: any;
  value?: any;
}

export default function ActionSelect(this: any, {
  className,
  items,
  select,
  placeholder,
  defaultValue,
  value,
}: ActionSelectProps) {
  const classes = useStyles();
  const handleSelect = (e: any) => {
    select(e);
  };

  const [valueUseState, setValueUseState] = useState();

  useEffect(() => {
    setValueUseState(value);
  }, [value]);

  return (
    <div className={clsx(classes.root, className)}>
      <select
        className={classes.container}
        onChange={(e) => handleSelect(e)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={valueUseState}>
        {items?.map((item: any, key: any) => {
          return (
            <option key={key} value={key}>
              {item}
            </option>
          );
        })}
      </select>
      <i className='fal fa-angle-down'></i>
    </div>
  );
}
