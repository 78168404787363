import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    backgroundColor: "white",
    width: "50%",
    maxWidth: 400,
    padding: "18px 40px",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loadingText: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
    paddingBottom: 14,
  },
  loadingImage: {
    width: 180,
    marginBottom: theme.spacing(2),
  },
  loadingDots: {
    display: "flex",
    "& span": {
      display: "inline-block",
      width: 10,
      height: 10,
      margin: theme.spacing(0, 0.5),
      backgroundColor: theme.palette.grey[300],
      borderRadius: "50%",
      animation: "$loadingAnimation 1.5s infinite",
      "&:nth-child(1)": {
        animationDelay: "0.5s",
      },
      "&:nth-child(2)": {
        animationDelay: "1s",
      },
      "&:nth-child(3)": {
        animationDelay: "1.5s",
      },
    },
  },
  "@keyframes loadingAnimation": {
    "0%, 80%, 100%": {
      backgroundColor: theme.palette.grey[300],
    },
    "40%": {
      backgroundColor: theme.palette.grey[800],
    },
  },
}));
