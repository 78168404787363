import { getToken } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiUsersInvitation(data: any) {
  const token = getToken()

  const response: any = await fetch(
    REQUEST_API_URL + `/users/${data.id}/invitation`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-store",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token
      },
      redirect: "error",
      referrerPolicy: "no-referrer-when-downgrade",
      body: JSON.stringify(data),
    }
  );
  return response.json();
}
