import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { REQUEST_API_URL } from "../config/constant";
import { getToken, getUserId } from "Utilities/appHelper";

export function ApiAccessLogs(callback: any) {
  const token = getToken()
  const userId = getUserId()
  const params = { userId: userId };
  const query = new URLSearchParams(params);

  fetch(REQUEST_API_URL + `/access_Logs?${query}`, {
    method: "GET",
    mode: "cors",
    cache: "no-store",
    credentials: "include",
    redirect: "error",
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      AfterApiIsLogin(data["result-status"]);
      callback(data["result-object"]);
    });
}
