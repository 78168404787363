import "./App.css";
import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import Layout from "layout/Layout";
import { LoginProxy } from "Utilities/LoginManager"

function App() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <LoginProxy>
        <Layout />
      </LoginProxy>
    </ThemeProvider>
  );
}

export default App;
