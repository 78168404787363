import { getToken } from "Utilities/appHelper";
import { REQUEST_API_URL } from "../config/constant";

export async function ApiUsersIdAsync(id: any) {
    const token = getToken()
    const response: any = await fetch(REQUEST_API_URL + '/users/' + id, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        headers: {
            Authorization: "Bearer " + token,
        },
    })

    return response.json();
}
