import { isEmpty } from "lodash";

const fullNameOrFamilyName = (
  fullName: string | null,
  firstName: string | null,
  lastName: string | null
): string => {
  return !!fullName || fullName === "" ? fullName : `${lastName} ${firstName}`;
};

const getClinetId = () => {
  const clinetId = localStorage.getItem("client_id") || ""
  return clinetId;
}


const getToken = () => localStorage.getItem("token") || "";
const getUserId = () => localStorage.getItem("user_key") || "";

export { fullNameOrFamilyName, getClinetId, getToken, getUserId };
