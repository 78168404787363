import clsx from "clsx";
import { ActionCheckboxStyles } from "./ActionCheckboxStyles";

interface ActionCheckboxProps {
  className?: any;
  value: string;
  action: (e: any) => void;
  checked: boolean;
  type?: string;
  readOnly?: boolean
}

export default function ActionCheckbox({
  className,
  value,
  action,
  checked,
  type,
  readOnly,
}: ActionCheckboxProps) {
  const classes = ActionCheckboxStyles();

  return (
    <input
      type={type}
      className={clsx(classes.root, className)}
      value={value}
      checked={checked}
      readOnly={readOnly}
      onChange={(e) => action(e.target.checked)}
    />
  );
}
