import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const ContactStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#EEF3F2",
      padding: "80px 48px 80px 48px",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        padding: "80px 24px 80px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    container: {
      background: "#fff",
      borderRadius: "10px",
      
      padding: "80px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(840)]: {
        padding: "48px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        padding: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    contactTitle: {
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "28px",
      fontWeight: 600,
      height: "auto",
      lineHeight: 1.4,
      margin: "0px 0px 48px 0px",
      textAlign: "center",
      transitionDelay: "300ms",
      transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        fontSize: "18px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
      [theme.breakpoints.down(440)]: {
        marginBottom: "24px",
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    contactForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      marginBottom: "24px",
    },
    formTitle: {
      color: "#55727e",
      fontFamily: "Noto Sans JP", //20230111
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: "0em",
      textAlign: "left",
      transitionDelay: "400ms",
      transitionDuration: "1000ms",
      marginBottom: "10px",
    },
    symbol: {
      color: "#f23a3c",
      fontFamily: "Noto Sans JP", //20230111
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: 1.4,
      marginRight: "5px",
    },
    formInput: {
      width: "100%",
      color: "#333333",
      fontFamily: "Noto Sans JP", //20230111
      fontSize: "15px",
      fontWeight: 400,
      border: "1px solid #eeeeee",
      borderRadius: "4px",
      lineHeight: 1.4,
      letterSpacing: "0.05em",
      textAlign: "left",
      padding: "14px 10px",
      boxShadow: "none",
      transitionDelay: "400ms",
      transitionDuration: "1000ms",
      boxSizing: "border-box",
      "&::placeholder": {
        fontFamily: "Noto Sans JP", //20230111
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: "0.05em",
        color: "#E8E8E8",
        opacity: 1 /* Firefox */,
      },
    },
    formTextarea: {
      width: "100% !important",
      color: "#333",
      fontFamily: "Noto Sans JP", //20230111
      fontSize: "15px",
      fontWeight: 400,
      border: "1px solid #eeeeee",
      borderRadius: "4px",
      lineHeight: 1.4,
      letterSpacing: "0.05em",
      textAlign: "left",
      padding: "14px 10px",
      boxShadow: "none",
      //transitionDelay: "400ms",
      //transitionDuration: "1000ms",
      minHeight: "130px",
      resize: "none",
      boxSizing: "border-box",
      "&::placeholder": {
        fontFamily: "Noto Sans JP", //20230111
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: 1.4,
        letterSpacing: "0.05em",
        color: "#E8E8E8",
        opacity: 1 /* Firefox */,
      },
      "&:focus-within": {
        outline: "2px solid #242727",
      },
    },
    formBtn: {
      marginTop: "24px",
      background: "#333333",
    },
    policy: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      color: "#000",
      //fontFamily: "ヒラギノ角ゴ W3 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      lineHeight: 1.4,
    },
    checkbox: {
      marginRight: "10px",
    },
    nomalA: {
      display: "inline",
      textDecoration: "underline",
    },
  })
);
